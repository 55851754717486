
import {
  Component,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { FetchPolicy } from 'apollo-client';
import BaseCard from '@/components/commonComponents/BaseCard.vue';
import DataTableHandler from '@/components/DataTable/DataTable';
import { Query } from '@/graphql/types';
import Message from '@/components/mixins/Message.vue';
import DataTable from '@/components/DataTable/DataTable.vue';

@Component({
  name: 'ReportDetail',
  components: {
    DataTable,
    BaseCard,
  },
})
export default class ReportDetail extends Vue {
  private dataTable = new DataTableHandler();

  private title = this.$route.params.dossierTypeSlug.charAt(0)
    .toUpperCase() + this.$route.params.dossierTypeSlug.slice(1)
    .replaceAll('-', ' ');

  private loading = false;

  private parentComponent = 'ReportDetail';

  @Watch('dataTable.options')
  optionsChanged(): void {
    this.fetchData('network-only');
  }

  private import(): Promise<Query> {
    const navigationGroup = this.$route.params.navigationGroup as string;
    switch (navigationGroup) {
      case 'status':
        return import('@/graphql/queries/status-group-report');
      case 'label':
        return import('@/graphql/queries/tag-group-report');
      case 'assign':
      case 'toewijzen':
        return import('@/graphql/queries/assign-group-report');
      case 'group':
      case 'groep':
        return import('@/graphql/queries/group-group-report');
      default:
        throw Error(`No import found for the report detail with the navigation group: ${navigationGroup}`);
    }
  }

  private fetchData(fetchPolicy: FetchPolicy = 'cache-first') {
    this.loading = true;

    const variables = {
      ...this.dataTable.getQueryVariables(),
      groupSlug: this.$route.params.groupSlug,
      dossierTypeSlug: this.$route.params.dossierTypeSlug,
      navigationSlug: this.$route.params.navigationSlug,
    };

    this.import()
      .then(({ QUERY: query }) => this.$apollo.query({
        fetchPolicy,
        query,
        variables,
      }))
      .then((response) => {
        this.dataTable.handleData(response.data);
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
